import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <div
        className="container-fluid page-header py-6 wow fadeIn"
        data-wow-delay="0.1s"
      >
        <div className="container-fluid page-header-inner py-5">
          <div className="container text-center pb-5">
            <h1 className="display-3 text-white mb-3 animated slideInDown">
              Air Freight
            </h1>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb justify-content-center text-uppercase">
                <li className="breadcrumb-item">
                  <Link to="/Home">Home</Link>
                </li>

                <li
                  className="breadcrumb-item text-white active"
                  aria-current="page"
                >
                  Air Freight
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      <div className="container-xxl py-5">
        <div className="container">
          <div className="row g-5 align-items-center">
            <div className="col-lg-12 text-center">
              <h1 className="mb-4">Air Freight</h1>
              <img
                className="img-fluid rounded  wow zoomIn"
                data-wow-delay="0.1s"
                src="img/Air Freight.jpg"
              />
            </div>
            <div className="col-lg-12">
              <p>
                In the realm of Air Freight Forwarding services, our experienced
                team of routing specialists takes the lead to ensure a seamless
                and efficient transportation process. We understand that your
                shipment's arrival time is a critical factor, and that's why we
                tailor our air logistics solutions to align with your specific
                delivery requirements. Whether you have a fixed delivery date or
                some flexibility, our experts are well-versed in international
                markets, enabling us to optimize routes and schedules to save
                you both time and money.
              </p>
              <p>
                If your cargo demands a swift and urgent delivery, our
                commitment to meeting tight deadlines is unwavering. Alongside
                our regular commercial lift options, we offer dedicated and
                part-charter flights to accommodate shipments from virtually any
                origin to any destination. This adaptability ensures that your
                goods are transported efficiently, regardless of their size or
                source. Our comprehensive approach to Air Freight Forwarding
                encompasses not only the operational aspect but also strategic
                thinking to ensure that your shipments arrive on time and in
                optimal condition.
              </p>
              <p>
                With a clear focus on customer satisfaction, we go the extra
                mile to provide tailored solutions that suit your unique
                shipping needs. Our ability to manage the intricacies of air
                transportation, combined with our dedication to offering
                competitive rates and reliable services, sets us apart in the
                industry. Whether it's handling routine shipments or managing
                time-sensitive deliveries, our Air Freight Forwarding services
                are built on a foundation of expertise, efficiency, and a
                commitment to meeting your logistics needs with precision.
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
