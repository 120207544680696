import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Services() {
  return (
    <>
      <Header />
     <div
        className="container-fluid page-header py-6 wow fadeIn"
        data-wow-delay="0.1s"
      >
        <div className="container-fluid page-header-inner py-5">
          <div className="container text-center pb-5">
            <h1 className="display-3 text-white mb-3 animated slideInDown">
            Services
            </h1>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb justify-content-center text-uppercase">
                <li className="breadcrumb-item">
                  <Link to="/Home">Home</Link>
                </li>
           
                <li
                  className="breadcrumb-item text-white active"
                  aria-current="page"
                >
                  Services
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      <div className="container-xxl py-5">
        <div className="container">
          <div
            className="text-center mx-auto wow fadeInUp"
            data-wow-delay="0.1s"
            style={{ maxWidth: "600px" }}
          >
            <p className="d-inline-block border rounded text-primary fw-semi-bold py-1 px-3">
              Our Services
            </p>
            <h1 className="display-5 mb-5">
              Freight Forwarding Services We Provide..
            </h1>
          </div>
          <div
            className="row project-carousel wow fadeInUp"
            data-wow-delay="0.3s"
          >
            <div className="project-item pe-5 pb-5 col-lg-4">
              <div className="project-img mb-3">
                <img
                  className="img-fluid rounded"
                  src="img/Air Freight.jpg"
                  alt=""
                />
                <Link to="/Air">
                  <i className="fa fa-link fa-3x text-primary" />
                </Link>
              </div>
              <div className="project-title">
                <h4 className="mb-0">Air Freight</h4>
              </div>
            </div>
            <div className="project-item pe-5 pb-5 col-lg-4">
              <div className="project-img mb-3">
                <img
                  className="img-fluid rounded"
                  src="img/Ocean Freight.jpg"
                  alt=""
                />
                <Link to="/Ocean">
                  <i className="fa fa-link fa-3x text-primary" />
                </Link>
              </div>
              <div className="project-title">
                <h4 className="mb-0">Ocean Freight</h4>
              </div>
            </div>
            <div className="project-item pe-5 pb-5 col-lg-4">
              <div className="project-img mb-3">
                <img
                  className="img-fluid rounded"
                  src="img/Road Freight.jpg"
                  alt=""
                />
                <Link to="/Road ">
                  <i className="fa fa-link fa-3x text-primary" />
                </Link>
              </div>
              <div className="project-title">
                <h4 className="mb-0">Road Freight</h4>
              </div>
            </div>
            <div className="project-item pe-5 pb-5 col-lg-4">
              <div className="project-img mb-3">
                <img
                  className="img-fluid rounded"
                  src="img/Project cargo logistics.jpg"
                  alt=""
                />
                <Link to="/Project">
                  <i className="fa fa-link fa-3x text-primary" />
                </Link>
              </div>
              <div className="project-title">
                <h4 className="mb-0">Project cargo logistics</h4>
              </div>
            </div>
            <div className="project-item pe-5 pb-5 col-lg-4">
              <div className="project-img mb-3">
                <img
                  className="img-fluid rounded"
                  src="img/Customs Clearance.jpg"
                  alt=""
                />
                <Link to="/Customs">
                  <i className="fa fa-link fa-3x text-primary" />
                </Link>
              </div>
              <div className="project-title">
                <h4 className="mb-0">Customs Clearance</h4>
              </div>
            </div>
            <div className="project-item pe-5 pb-5 col-lg-4">
              <div className="project-img mb-3">
                <img
                  className="img-fluid rounded"
                  src="img/Warehouse Management.jpg"
                  alt=""
                />
                <Link to="/Warehouse">
                  <i className="fa fa-link fa-3x text-primary" />
                </Link>
              </div>
              <div className="project-title">
                <h4 className="mb-0">Warehouse Management</h4>
              </div>
            </div>
            
            
          </div>
        </div>
      </div>
      {/* Projects End */}
      {/* Team Start */}
      <div className="container-xxl py-5">
        <div className="container">
          <div
            className="text-center mx-auto wow fadeInUp"
            data-wow-delay="0.1s"
            style={{ maxWidth: "600px" }}
          >
            <p className="d-inline-block border rounded text-primary fw-semi-bold py-1 px-3">
              Our Services
            </p>
            <h1 className="display-5 mb-5">Our Trade Consultancy</h1>
          </div>
          <div className="row g-4">
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="team-item">
                <img
                  className="img-fluid rounded"
                  src="img/Pharmaceutical.jpg"
                  alt=""
                />
                <div className="team-text">
                  <h4 className="mb-0">Pharmaceutical  </h4>
                  <div className="team-social d-flex">
                    <Link className="btn btn-square rounded-circle mx-1" to="/Trade">
                    <i class="fa fa-arrow-right ms-1"></i>
                    </Link>
                   
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="team-item">
                <img
                  className="img-fluid rounded"
                  src="img/Manufacturing.jpg"
                  alt=""
                />
                <div className="team-text">
                  <h4 className="mb-0">Manufacturing </h4>
                  <div className="team-social d-flex">
                    <Link className="btn btn-square rounded-circle mx-1" to="/Trade">
                    <i class="fa fa-arrow-right ms-1"></i>
                    </Link>
                   
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="team-item">
                <img
                  className="img-fluid rounded"
                  src="img/Telecom.jpg"
                  alt=""
                />
                <div className="team-text">
                  <h4 className="mb-0">Telecom </h4>
                  <div className="team-social d-flex">
                    <Link className="btn btn-square rounded-circle mx-1" to="/Trade">
                    <i class="fa fa-arrow-right ms-1"></i>
                    </Link>
                   
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-2 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
             
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="team-item">
                <img
                  className="img-fluid rounded"
                  src="img/Education.jpg"
                  alt=""
                />
                <div className="team-text">
                  <h4 className="mb-0">Education </h4>
                  <div className="team-social d-flex">
                    <Link className="btn btn-square rounded-circle mx-1" to="/Trade">
                    <i class="fa fa-arrow-right ms-1"></i>
                    </Link>
                   
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="team-item">
                <img
                  className="img-fluid rounded"
                  src="img/Engineering.jpg"
                  alt=""
                />
                <div className="team-text">
                  <h4 className="mb-0">Engineering </h4>
                  <div className="team-social d-flex">
                    <Link className="btn btn-square rounded-circle mx-1" to="/Trade">
                    <i class="fa fa-arrow-right ms-1"></i>
                    </Link>
                   
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-2 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
             
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}
