import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <div
        className="container-fluid page-header py-6 wow fadeIn"
        data-wow-delay="0.1s"
      >
        <div className="container-fluid page-header-inner py-5">
          <div className="container text-center pb-5">
            <h1 className="display-3 text-white mb-3 animated slideInDown">
              Warehouse Management
            </h1>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb justify-content-center text-uppercase">
                <li className="breadcrumb-item">
                  <Link to="/Home">Home</Link>
                </li>

                <li
                  className="breadcrumb-item text-white active"
                  aria-current="page"
                >
                  Warehouse Management
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      <div className="container-xxl py-5">
        <div className="container">
          <div className="row g-5 align-items-center">
            <div className="col-lg-12 text-center">
              <h1 className="mb-4">Warehouse Management</h1>
              <img
                className="img-fluid rounded  wow zoomIn"
                data-wow-delay="0.1s"
                src="img/Warehouse Management.jpg"
              />
            </div>
            <div className="col-lg-12">
              <p>
                Unlocking the advantages of economies of scale, our multi-user
                logistics sites offer you a flexible and cost-effective
                solution. With our unique approach, you pay only for the space
                you utilize on a month-to-month basis. This scalability ensures
                that your logistics needs align precisely with your business
                requirements, promoting efficiency and minimizing costs.
              </p>
              <p>
                Recognizing the complexities of global expansion, many of our
                clients seek to replicate successful processes from one facility
                to another, even across different countries. As seasoned experts
                in managing such intricate projects, we excel in implementing
                these strategies. Our proficiency extends across borders,
                allowing us to rapidly deploy tailored multi-channel logistics
                solutions virtually anywhere in the world.
              </p>
              <p>
                At the heart of our capability is meticulous project management.
                We understand that successful replication and expansion
                necessitate careful planning, execution, and monitoring. With
                our extensive reach and experience, we orchestrate every detail
                of these projects to ensure seamless integration and the
                achievement of your expansion goals.
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
