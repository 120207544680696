import { useState } from "react";
import { useHistory } from "react-router-dom";
import $ from "jquery";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";
import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Home() {
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [result, setResult] = useState("");

  const handleFnameChange = (e) => {
    setFname(e.target.value);
  };
  const handlelnameChange = (e) => {
    setLname(e.target.value);
  };
  const handleemailChange = (e) => {
    setEmail(e.target.value);
  };
  const handlesubjectChange = (e) => {
    setSubject(e.target.value);
  };
  const handlemessageChange = (e) => {
    setMessage(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = $(e.target);
    $.ajax({
      type: "POST",
      url: form.attr("action"),
      data: form.serialize(),
      success(data) {
        setResult(data);
      },
    });
  };
  return (
    <>
      <Header />

      {/* Carousel Start */}
      <div
        className="container-fluid p-0 mb-5 wow fadeIn w3-banner jarallax "
        data-wow-delay="0.1s"
        style={{ height: "750px" }}
      >
        <video
          className="img-fluid"
          autoPlay
          muted
          loop
          style={{ zIndex: "-1" }}
        >
          <source src="img/video.mp4" type="video/mp4" />
        </video>
       
      </div>
      {/* Carousel End */}
      {/* About Start */}
      <div className="container-xxl py-5">
        <div className="container">
          <div className="row g-4 align-items-end mb-4">
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
              <img className="img-fluid rounded" src="img/about.jpg" />
            </div>
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.3s">
              <p className="d-inline-block border rounded text-primary fw-semi-bold py-1 px-3">
                About Us
              </p>
              <h1 className="display-5 mb-4">Welcome to {companyname}</h1>
              <p className="mb-4">
                At {companyname}, we stand as a premier Freight and Trade
                consultancy provider, possessing a wealth of industry expertise
                that is both extensive and profound. Our comprehensive knowledge
                spans diverse channels and global regions, underpinned by an
                unwavering commitment to enhancing our clients' end-to-end
                Global retail supply chains.
              </p>
             
              <div className="border rounded p-4">
                <nav>
                  <div
                    className="nav nav-tabs mb-3"
                    id="nav-tab"
                    role="tablist"
                  >
                    <button
                      className="nav-link fw-semi-bold"
                      id="nav-mission-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#nav-mission"
                      type="button"
                      role="tab"
                      aria-controls="nav-mission"
                      aria-selected="false"
                    >
                      Mission
                    </button>
                    <button
                      className="nav-link fw-semi-bold"
                      id="nav-vision-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#nav-vision"
                      type="button"
                      role="tab"
                      aria-controls="nav-vision"
                      aria-selected="false"
                    >
                      Vision
                    </button>
                  </div>
                </nav>
                <div className="tab-content" id="nav-tabContent">
                  <div
                    className="tab-pane fade show active"
                    id="nav-mission"
                    role="tabpanel"
                    aria-labelledby="nav-mission-tab"
                  >
                    <p>
                      Our aspiration is to lead the Global logistics industry by
                      offering solutions that revolve around our clients,
                      propelling businesses forward and consistently surpassing
                      expectations.
                    </p>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="nav-vision"
                    role="tabpanel"
                    aria-labelledby="nav-vision-tab"
                  >
                    <p>
                      Our vision is to emerge as the foremost
                      entrepreneurial-powered, worldwide logistics enterprise.
                      Our foundation is rooted in our capability to deliver
                      cutting-edge, astute solutions for supply and demand
                      chains that revolutionize our clients' enterprises.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="border rounded p-4 wow fadeInUp text-center"
            data-wow-delay="0.1s"
          >
           
            <Link
                        to="/About"
                        className="btn btn-primary py-3 px-5 animated slideInDown"
                      >
                        Learn More
                      </Link>
            
          </div>
        </div>
      </div>

      {/* Callback End */}
      {/* Projects Start */}
      <div className="container-xxl py-5">
        <div className="container">
          <div
            className="text-center mx-auto wow fadeInUp"
            data-wow-delay="0.1s"
            style={{ maxWidth: "600px" }}
          >
            <p className="d-inline-block border rounded text-primary fw-semi-bold py-1 px-3">
              Our Services
            </p>
            <h1 className="display-5 mb-5">
              Freight Forwarding Services We Provide..
            </h1>
          </div>
          <div
            className="row project-carousel wow fadeInUp"
            data-wow-delay="0.3s"
          >
            <div className="project-item pe-5 pb-5 col-lg-4">
              <div className="project-img mb-3">
                <img
                  className="img-fluid rounded"
                  src="img/Air Freight.jpg"
                  alt=""
                />
                <Link to="/Air">
                  <i className="fa fa-link fa-3x text-primary" />
                </Link>
              </div>
              <div className="project-title">
                <h4 className="mb-0">Air Freight</h4>
              </div>
            </div>
            <div className="project-item pe-5 pb-5 col-lg-4">
              <div className="project-img mb-3">
                <img
                  className="img-fluid rounded"
                  src="img/Ocean Freight.jpg"
                  alt=""
                />
                <Link to="/Ocean">
                  <i className="fa fa-link fa-3x text-primary" />
                </Link>
              </div>
              <div className="project-title">
                <h4 className="mb-0">Ocean Freight</h4>
              </div>
            </div>
            <div className="project-item pe-5 pb-5 col-lg-4">
              <div className="project-img mb-3">
                <img
                  className="img-fluid rounded"
                  src="img/Road Freight.jpg"
                  alt=""
                />
                <Link to="/Road ">
                  <i className="fa fa-link fa-3x text-primary" />
                </Link>
              </div>
              <div className="project-title">
                <h4 className="mb-0">Road Freight</h4>
              </div>
            </div>
            <div className="project-item pe-5 pb-5 col-lg-4">
              <div className="project-img mb-3">
                <img
                  className="img-fluid rounded"
                  src="img/Project cargo logistics.jpg"
                  alt=""
                />
                <Link to="/Project">
                  <i className="fa fa-link fa-3x text-primary" />
                </Link>
              </div>
              <div className="project-title">
                <h4 className="mb-0">Project cargo logistics</h4>
              </div>
            </div>
            <div className="project-item pe-5 pb-5 col-lg-4">
              <div className="project-img mb-3">
                <img
                  className="img-fluid rounded"
                  src="img/Customs Clearance.jpg"
                  alt=""
                />
                <Link to="/Customs">
                  <i className="fa fa-link fa-3x text-primary" />
                </Link>
              </div>
              <div className="project-title">
                <h4 className="mb-0">Customs Clearance</h4>
              </div>
            </div>
            <div className="project-item pe-5 pb-5 col-lg-4">
              <div className="project-img mb-3">
                <img
                  className="img-fluid rounded"
                  src="img/Warehouse Management.jpg"
                  alt=""
                />
                <Link to="/Warehouse">
                  <i className="fa fa-link fa-3x text-primary" />
                </Link>
              </div>
              <div className="project-title">
                <h4 className="mb-0">Warehouse Management</h4>
              </div>
            </div>
            
            
          </div>
        </div>
      </div>
      {/* Projects End */}
      
      <div className="container-xxl feature py-5">
        <div className="container">
          <div className="row g-5 align-items-center">
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
              <p className="d-inline-block border rounded text-primary fw-semi-bold py-1 px-3">
                Why Choosing Us!
              </p>
              <h1 className="display-5 mb-4">
              Turning Challenges into Shipments, Worldwide!
              </h1>
              <p className="mb-4">
              we embrace challenges as opportunities to showcase our expertise and dedication in the realm of global logistics. 
              </p>
              <Link className="btn btn-primary py-3 px-5" to="/About">
                Learn More
              </Link>
            </div>
            <div className="col-lg-6">
              <div className="row g-4 align-items-center">
                <div className="col-md-6">
                  <div className="row g-4">
                    <div className="col-12 wow fadeIn" data-wow-delay="0.3s">
                      <div className="feature-box border rounded p-4">
                        <i className="fa fa-check fa-3x text-primary mb-3" />
                        <h4 className="mb-3">Our Mission</h4>
                        <p className="mb-3">
                        Our aspiration is to lead the Global logistics industry by offering solutions that revolve around our clients, propelling businesses forward and consistently surpassing expectations.
                        </p>
                       
                      </div>
                    </div>
                    <div className="col-12 wow fadeIn" data-wow-delay="0.5s">
                      <div className="feature-box border rounded p-4">
                        <i className="fa fa-check fa-3x text-primary mb-3" />
                        <h4 className="mb-3">Our Vision</h4>
                        <p className="mb-3">
                        Our vision is to emerge as the foremost entrepreneurial-powered, worldwide logistics enterprise. Our foundation is rooted in our capability to deliver cutting-edge, astute solutions for supply and demand chains that revolutionize our clients' enterprises.
                        </p>
                        
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 wow fadeIn" data-wow-delay="0.7s">
                  <div className="feature-box border rounded p-4">
                    <i className="fa fa-check fa-3x text-primary mb-3" />
                    <h4 className="mb-3">Our Value</h4>
                    <p className="mb-3">
                    {companyname} has always been values-driven. Our shared values guide our actions that help us make a difference. These values continue to direct the growth and business
                    </p>
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Features End */}

      {/* Callback Start */}
      <div className="container-fluid callback my-5 pt-5">
        <div className="container pt-5">
          <div className="row justify-content-center">
            <div className="col-lg-7">
              <div
                className="bg-white border rounded p-4 p-sm-5 wow fadeInUp"
                data-wow-delay="0.5s"
              >
                <div
                  className="text-center mx-auto wow fadeInUp"
                  data-wow-delay="0.1s"
                  style={{ maxWidth: "600px" }}
                >
                  <p className="d-inline-block border rounded text-primary fw-semi-bold py-1 px-3">
                    Get In Touch
                  </p>
                  <h1 className="display-5 mb-5">Request A Call-Back</h1>
                </div>
                <form
                action="/php/thankyou-contact.php "
                method="post"
                onSubmit={(event) => handleSubmit(event)}
              >
                <div className="row g-3">
                  <div className="col-md-6">
                    <div className="control-group">
                      <input
                        onChange={(event) => handleFnameChange(event)}
                        type="text"
                        id="fname"
                        required
                        name="fname"
                        className="form-control border-0 bg-light"
                        value={fname}
                        placeholder="Enter your first name"
                        style={{
                          width: "100%",
                          padding: "10px",
                          margin: "6px 0",
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="control-group">
                      <input
                        onChange={(event) => handlelnameChange(event)}
                        className="form-control border-0 bg-light"
                        type="text"
                        required
                        style={{
                          width: "100%",
                          padding: "10px",
                          margin: "6px 0",
                        }}
                        id="lname"
                        name="lname"
                        value={lname}
                        placeholder="Enter your last name"
                      />
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="control-group">
                      <input
                        onChange={(event) => handleemailChange(event)}
                        className="form-control border-0 bg-light"
                        type="email"
                        style={{
                          width: "100%",
                          padding: "10px",
                          margin: "6px 0",
                        }}
                        id="email"
                        name="email"
                        value={email}
                        placeholder="Enter your Email"
                      />
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="control-group">
                      <input
                        onChange={(event) => handlesubjectChange(event)}
                        className="form-control border-0 bg-light"
                        type="text"
                        style={{
                          width: "100%",
                          padding: "10px",
                          margin: "6px 0",
                        }}
                        id="subject"
                        name="subject"
                        value={subject}
                        placeholder="Enter subject"
                      />
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="control-group">
                      <textarea
                        className="form-control border-0 bg-light"
                        style={{
                          width: "100%",
                          padding: "10px",
                          margin: "6px 0",
                        }}
                        id="message"
                        name="message"
                        rows={1}
                        placeholder="Enter Message"
                        defaultValue={""}
                        value={message}
                        onChange={(event) => handlemessageChange(event)}
                      />
                    </div>
                  </div>
                  <div className="col-12">
                    <button
                      name="submit"
                      className="btn btn-primary w-100 py-3"
                      type="submit"
                    >
                      Send Message
                    </button>
                  </div>
                </div>
              </form>
              <h1>{result}</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Team Start */}
      <div className="container-xxl py-5">
        <div className="container">
          <div
            className="text-center mx-auto wow fadeInUp"
            data-wow-delay="0.1s"
            style={{ maxWidth: "600px" }}
          >
            <p className="d-inline-block border rounded text-primary fw-semi-bold py-1 px-3">
              Our Services
            </p>
            <h1 className="display-5 mb-5">Our Trade Consultancy</h1>
          </div>
          <div className="row g-4">
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="team-item">
                <img
                  className="img-fluid rounded"
                  src="img/Pharmaceutical.jpg"
                  alt=""
                />
                <div className="team-text">
                  <h4 className="mb-0">Pharmaceutical  </h4>
                  <div className="team-social d-flex">
                    <Link className="btn btn-square rounded-circle mx-1" to="/Trade">
                    <i class="fa fa-arrow-right ms-1"></i>
                    </Link>
                   
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="team-item">
                <img
                  className="img-fluid rounded"
                  src="img/Manufacturing.jpg"
                  alt=""
                />
                <div className="team-text">
                  <h4 className="mb-0">Manufacturing </h4>
                  <div className="team-social d-flex">
                    <Link className="btn btn-square rounded-circle mx-1" to="/Trade">
                    <i class="fa fa-arrow-right ms-1"></i>
                    </Link>
                   
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="team-item">
                <img
                  className="img-fluid rounded"
                  src="img/Telecom.jpg"
                  alt=""
                />
                <div className="team-text">
                  <h4 className="mb-0">Telecom </h4>
                  <div className="team-social d-flex">
                    <Link className="btn btn-square rounded-circle mx-1" to="/Trade">
                    <i class="fa fa-arrow-right ms-1"></i>
                    </Link>
                   
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-2 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
             
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="team-item">
                <img
                  className="img-fluid rounded"
                  src="img/Education.jpg"
                  alt=""
                />
                <div className="team-text">
                  <h4 className="mb-0">Education </h4>
                  <div className="team-social d-flex">
                    <Link className="btn btn-square rounded-circle mx-1" to="/Trade">
                    <i class="fa fa-arrow-right ms-1"></i>
                    </Link>
                   
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="team-item">
                <img
                  className="img-fluid rounded"
                  src="img/Engineering.jpg"
                  alt=""
                />
                <div className="team-text">
                  <h4 className="mb-0">Engineering </h4>
                  <div className="team-social d-flex">
                    <Link className="btn btn-square rounded-circle mx-1" to="/Trade">
                    <i class="fa fa-arrow-right ms-1"></i>
                    </Link>
                   
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-2 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
             
            </div>
          </div>
        </div>
      </div>


      <Footer />
    </>
  );
}
