import { useState } from "react";
import { useHistory } from "react-router-dom";
import $ from "jquery";
import Header from "../components/Header";
import { Outlet, Link } from "react-router-dom";
import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "./Companyinfo";

const Footer = () => {
  const [subemail, setSubemail] = useState("");
  const [result, setResult] = useState("");

  const handleSubemailChange = (e) => {
    setSubemail(e.target.value);
  };
 


  const handleSubmit = (e) => {
    e.preventDefault();
    const form = $(e.target);
    $.ajax({
      type: "POST",
      url: form.attr("action"),
      data: form.serialize(),
      success(data) {
        setResult(data);
      },
    });
  };
 
  return (
    <>
     {/* Footer Start */}
     <div className="container-fluid bg-dark text-light footer mt-5 py-5 wow fadeIn" data-wow-delay="0.1s">
          <div className="container py-5">
            <div className="row g-5">
              <div className="col-lg-3 col-md-6">
                <h4 className="text-white mb-4">Our Office</h4>
                <p className="mb-2"><i className="fa fa-map-marker-alt me-3" />{companyaddress}</p>
                <p className="mb-2"><i className="fa fa-phone-alt me-3" />{companynumber}</p>
                <p className="mb-2"><i className="fa fa-envelope me-3" />{companyemail}</p>
               
              </div>
              <div className="col-lg-3 col-md-6">
                <h4 className="text-white mb-4">Services</h4>
                <Link to="/Air" className="btn btn-link">Air Freight</Link>
                    <Link to="/Ocean" className="btn btn-link">Ocean Freight</Link>
                    <Link to="/Road" className="btn btn-link">Road Freight</Link>
                    <Link to="/Project" className="btn btn-link">Project cargo logistics</Link>
                    <Link to="/Customs" className="btn btn-link">Customs Clearance</Link>
                    <Link to="/Warehouse" className="btn btn-link">Warehouse Management</Link>   
              </div>
              <div className="col-lg-3 col-md-6">
                <h4 className="text-white mb-4">Quick Links</h4>
                <Link className="btn btn-link" to="/Home">Home</Link>
          <Link className="btn btn-link" to="/About">Company profile</Link>
          <Link className="btn btn-link" to="/Services">Services</Link>
          <Link className="btn btn-link" to="/Trade">Trade Consultancy</Link>
          <Link className="btn btn-link" to="/Contact">Contact</Link>
          <Link className="btn btn-link" to="/Getquote">Place Quote</Link>
              </div>
              <div className="col-lg-3 col-md-6">
                <h4 className="text-white mb-4">Newsletter</h4>
                <p>Subscribe to Our Newsletter </p>
                <div className="position-relative w-100">
                <form
                action="http://localhost/php/thankyou-subscribe.php "
                method="POST"
                onSubmit={(event) => handleSubmit(event)}
              >
                  <input  onChange={(event) => handleSubemailChange(event)}
                  id="subemail" value={subemail}
                    className="form-control w-100 py-3 ps-4 pe-5"
                    type="text"
                    placeholder="Enter your email"
                  /> 
                  <button
                    type="submit"  name="submit"
                    className="btn btn-primary py-2 px-3 position-absolute top-0 end-0 mt-2 me-2"
                  >
                    Submit
                  </button></form>
                  <h1>{result}</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Footer End */}
        {/* Copyright Start */}
        <div className="container-fluid copyright py-4">
          <div className="container">
            <div className="row">
              <div className="col-md-6 text-center text-md-start mb-3 mb-md-0">
                ©2023 <Link className="border-bottom" to="/">{companyname}</Link>, All Right Reserved.
              </div>
              <div className="col-md-6 text-center text-md-end">
                {/*/*** This template is free as long as you keep the footer author’s credit link/attribution link/backlink. If you'd like to use the template without the footer author’s credit link/attribution link/backlink, you can purchase the Credit Removal License from "https://htmlcodex.com/credit-removal". Thank you for your support. *** /*/}
                 <Link className="" to="/Privacy">Privacy Policy </Link> <Link className="" to="/Terms">Terms</Link>
              </div>
            </div>
          </div>
        </div>
    </>
  );
};

export default Footer;
