import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <div
        className="container-fluid page-header py-6 wow fadeIn"
        data-wow-delay="0.1s"
      >
        <div className="container-fluid page-header-inner py-5">
          <div className="container text-center pb-5">
            <h1 className="display-3 text-white mb-3 animated slideInDown">
              Trade Consultancy
            </h1>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb justify-content-center text-uppercase">
                <li className="breadcrumb-item">
                  <Link to="/Home">Home</Link>
                </li>

                <li
                  className="breadcrumb-item text-white active"
                  aria-current="page"
                >
                  Trade Consultancy
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      {/* Service Start */}
      <div className="container-xxl service py-5">
        <div className="container">
          <div
            className="text-center mx-auto wow fadeInUp"
            data-wow-delay="0.1s"
            style={{ maxWidth: "600px" }}
          >
            <p className="d-inline-block border rounded text-primary fw-semi-bold py-1 px-3">
              Our Services
            </p>
            <h1 className="display-5 mb-5">
            Trade Consultancy
            </h1>
          </div>
          <div className="row g-3 wow fadeInUp" data-wow-delay="0.3s">
            <div className="col-lg-3">
              <div className="nav nav-pills d-flex justify-content-between w-100 h-100 me-4">
                <button
                  className="nav-link w-100 d-flex align-items-center text-start border p-4 mb-4 active"
                  data-bs-toggle="pill"
                  data-bs-target="#tab-pane-1"
                  type="button"
                >
                  <h5 className="m-0">
                    <i className="fa fa-bars text-primary me-3" />
                    Pharmaceutical / Life Sciences
                  </h5>
                </button>
                <button
                  className="nav-link w-100 d-flex align-items-center text-start border p-4 mb-4"
                  data-bs-toggle="pill"
                  data-bs-target="#tab-pane-2"
                  type="button"
                >
                  <h5 className="m-0">
                    <i className="fa fa-bars text-primary me-3" />
                    Manufacturing
                  </h5>
                </button>
                <button
                  className="nav-link w-100 d-flex align-items-center text-start border p-4 mb-4"
                  data-bs-toggle="pill"
                  data-bs-target="#tab-pane-3"
                  type="button"
                >
                  <h5 className="m-0">
                    <i className="fa fa-bars text-primary me-3" />
                    Telecom
                  </h5>
                </button>
                <button
                  className="nav-link w-100 d-flex align-items-center text-start border p-4 mb-4"
                  data-bs-toggle="pill"
                  data-bs-target="#tab-pane-4"
                  type="button"
                >
                  <h5 className="m-0">
                    <i className="fa fa-bars text-primary me-3" />
                    Manufacturing
                  </h5>
                </button>
                <button
                  className="nav-link w-100 d-flex align-items-center text-start border p-4 mb-0"
                  data-bs-toggle="pill"
                  data-bs-target="#tab-pane-5"
                  type="button"
                >
                  <h5 className="m-0">
                    <i className="fa fa-bars text-primary me-3" />
                    Engineering
                  </h5>
                </button>
              </div>
            </div>
            <div className="col-lg-9">
              <div className="tab-content w-100">
                <div className="tab-pane fade show active" id="tab-pane-1">
                  <div className="row g-4">
                    <div className="col-md-12" style={{ minHeight: "450px" }}>
                      <div className="position-relative h-100">
                        <img
                          className="position-absolute rounded w-100 h-100"
                          src="img/Pharmaceutical.jpg"
                          style={{ objectFit: "cover" }}
                          alt=""
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <h3 className="mb-4">Pharmaceutical / Life Sciences</h3>
                      <p className="mb-4">
                        content
                      </p>
                    </div>
                  </div>
                </div>
                <div className="tab-pane fade show " id="tab-pane-2">
                  <div className="row g-4">
                    <div className="col-md-12" style={{ minHeight: "450px" }}>
                      <div className="position-relative h-100">
                        <img
                          className="position-absolute rounded w-100 h-100"
                          src="img/Manufacturing.jpg"
                          style={{ objectFit: "cover" }}
                          alt=""
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <h3 className="mb-4">Manufacturing</h3>
                      <p className="mb-4">
                        content
                      </p>
                    </div>
                  </div>
                </div>
                <div className="tab-pane fade show " id="tab-pane-3">
                  <div className="row g-4">
                    <div className="col-md-12" style={{ minHeight: "450px" }}>
                      <div className="position-relative h-100">
                        <img
                          className="position-absolute rounded w-100 h-100"
                          src="img/Telecom.jpg"
                          style={{ objectFit: "cover" }}
                          alt=""
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <h3 className="mb-4">Telecom</h3>
                      <p className="mb-4">
                        Telecom
                      </p>
                    </div>
                  </div>
                </div>
                <div className="tab-pane fade show " id="tab-pane-4">
                  <div className="row g-4">
                    <div className="col-md-12" style={{ minHeight: "450px" }}>
                      <div className="position-relative h-100">
                        <img
                          className="position-absolute rounded w-100 h-100"
                          src="img/Education.jpg"
                          style={{ objectFit: "cover" }}
                          alt=""
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <h3 className="mb-4">Education</h3>
                      <p className="mb-4">
                        content
                      </p>
                    </div>
                  </div>
                </div>
                <div className="tab-pane fade show " id="tab-pane-5">
                  <div className="row g-4">
                    <div className="col-md-12" style={{ minHeight: "450px" }}>
                      <div className="position-relative h-100">
                        <img
                          className="position-absolute rounded w-100 h-100"
                          src="img/Engineering.jpg"
                          style={{ objectFit: "cover" }}
                          alt=""
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <h3 className="mb-4">Engineering</h3>
                      <p className="mb-4">
                        content
                      </p>
                    </div>
                  </div>
                </div>
               
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Service End */}
      <Footer />
    </>
  );
}
