import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <div
        className="container-fluid page-header py-6 wow fadeIn"
        data-wow-delay="0.1s"
      >
        <div className="container-fluid page-header-inner py-5">
          <div className="container text-center pb-5">
            <h1 className="display-3 text-white mb-3 animated slideInDown">
              Road Freight
            </h1>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb justify-content-center text-uppercase">
                <li className="breadcrumb-item">
                  <Link to="/Home">Home</Link>
                </li>

                <li
                  className="breadcrumb-item text-white active"
                  aria-current="page"
                >
                  Road Freight
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      <div className="container-xxl py-5">
        <div className="container">
          <div className="row g-5 align-items-center">
            <div className="col-lg-12 text-center">
              <h1 className="mb-4">Road Freight</h1>
              <img
                className="img-fluid rounded  wow zoomIn"
                data-wow-delay="0.1s"
                src="img/Road Freight.jpg"
              />
            </div>
            <div className="col-lg-12">
              <p>
              {companyname} stands as a trusted provider of time-definite and
                specialized services, supported by our fleet of {companyname}-branded
                trucks, vans, and equipment. Our capabilities extend to diverse
                scenarios, whether it involves handling oversized cargo or
                orchestrating deliveries before store openings, ensuring a
                seamless experience for your retail expansion, trade shows,
                events, or any specialty cargo requirements.
              </p>
              <p>
                When it comes to heavyweight home delivery, {companyname}'s Home Delivery
                and Last Mile service emerges as the optimum solution for
                final-mile delivery of eCommerce, bulky, oversized, and
                high-value goods. With our time-definite appointments, online
                delivery scheduling, and automated service notifications, we
                ensure a smooth delivery experience. Our service also
                encompasses valuable add-ons like setup, debris removal, and
                other customizable options.
              </p>
              <p>
                At {companyname}, we excel in specialized handling. We offer a
                comprehensive array of project cargo services, including those
                tailored to flatbeds, oversized items, and cargo with unique
                size and weight requirements. In addition, we specialize in cold
                chain logistics, exemplified by our management of beloved
                point-of-sale brands like Dippin' Dots. We enable the
                replenishment of stock while prioritizing health and safety in
                the process.
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
