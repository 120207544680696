import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <div
        className="container-fluid page-header py-6 wow fadeIn"
        data-wow-delay="0.1s"
      >
        <div className="container-fluid page-header-inner py-5">
          <div className="container text-center pb-5">
            <h1 className="display-3 text-white mb-3 animated slideInDown">
              Ocean Freight
            </h1>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb justify-content-center text-uppercase">
                <li className="breadcrumb-item">
                  <Link to="/Home">Home</Link>
                </li>

                <li
                  className="breadcrumb-item text-white active"
                  aria-current="page"
                >
                  Ocean Freight
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      <div className="container-xxl py-5">
        <div className="container">
          <div className="row g-5 align-items-center">
            <div className="col-lg-12 text-center">
              <h1 className="mb-4">Ocean Freight</h1>
              <img
                className="img-fluid rounded  wow zoomIn"
                data-wow-delay="0.1s"
                src="img/Ocean Freight.jpg"
              />
            </div>
            <div className="col-lg-12">
              <p>
                Our extensive network of partnerships with the world's leading
                ocean carriers is a cornerstone of our Ocean Freight Forwarding
                services. These strong affiliations empower us to provide you
                with unparalleled flexibility in routing and a multitude of
                ocean transportation choices. Whether your cargo requires part
                container (LCL) or full container (FCL) movement, we have the
                expertise to ensure efficient and reliable ocean shipping
                options tailored to your needs.
              </p>
              <p>
              {companyname}'s commitment to optimizing your supply chain extends beyond
                traditional services. We offer consolidation and
                de-consolidation of ocean shipments both at the point of origin
                and the destination. This strategic approach streamlines your
                logistics process and enhances your operational efficiency. Our
                comprehensive range of Global shipping logistics services
                encompasses every step of the shipping journey, ensuring that
                your cargo arrives at its destination with accuracy and
                punctuality.
              </p>
              <p>
                By leveraging our robust network, industry know-how, and
                cutting-edge logistics solutions, we're able to offer a full
                spectrum of options for ocean freight. Our dedication to
                providing reliable and cost-effective solutions, coupled with
                our commitment to customer satisfaction, positions us as a
                leader in the Ocean Freight Forwarding domain. No matter the
                size or complexity of your shipment, our services are designed
                to optimize your shipping experience and contribute to the
                success of your global supply chain.
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
