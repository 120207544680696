import { Outlet, Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "./Companyinfo";

const Header = () => {
  return (
    <>
      <div
        className="container-fluid fixed-top px-0 wow fadeIn"
        data-wow-delay="0.1s"
      >
        <div className="top-bar row gx-0 align-items-center d-none d-lg-flex" style={{background:"black"}}>
          <div className="col-lg-7 px-5 text-start">
            <small>
              <i className="fa fa-map-marker-alt text-primary me-2" />
              {companyaddress}
            </small>
          </div>
          <div className="col-lg-5 px-5 text-end">
            <small>
              <i className="fa fa-envelope text-primary me-2" />
              {companyemail}
            </small>
            <small className="ms-4">
              <i className="fa fa-phone-alt text-primary me-2" />
              {companynumber}
            </small>
          </div>
        </div>
        <nav
          className="navbar navbar-expand-lg navbar-light py-lg-0 px-lg-5 wow fadeIn"
          data-wow-delay="0.1s"
          style={{ background: "white" }}
        >
          <Link to="index.html" className="navbar-brand ms-4 ms-lg-0">
            <img src="img/logo.png" style={{ width: "95px" }}></img>
          </Link>
          <button
            type="button"
            className="navbar-toggler me-4"
            data-bs-toggle="collapse"
            data-bs-target="#navbarCollapse"
          >
            <span className="navbar-toggler-icon" />
          </button>
          <div className="collapse navbar-collapse" id="navbarCollapse">
            <div className="navbar-nav ms-auto p-4 p-lg-0">
              <Link to="/Home" className="nav-item nav-link active">
                Home
              </Link>
              <Link to="/About" className="nav-item nav-link">
                Company Profile
              </Link>
              <div className="nav-item dropdown">
                <Link
                  to="#"
                  className="nav-link dropdown-toggle"
                  data-bs-toggle="dropdown"
                >
                  What We Offer
                </Link>
                <div className="dropdown-menu border-light m-0">
                  <Link to="/Air" className="dropdown-item">
                    Air Freight
                  </Link>
                  <Link to="/Ocean" className="dropdown-item">
                    Ocean Freight
                  </Link>
                  <Link to="/Road" className="dropdown-item">
                    Road Freight
                  </Link>
                  <Link to="/Project" className="dropdown-item">
                    Project cargo logistics
                  </Link>
                  <Link to="/Customs" className="dropdown-item">
                    Customs Clearance
                  </Link>
                  <Link to="/Warehouse" className="dropdown-item">
                    Warehouse Management
                  </Link>
                </div>
              </div>
              <div className="nav-item dropdown">
                <Link
                  to="#"
                  className="nav-link dropdown-toggle"
                  data-bs-toggle="dropdown"
                >
                  Trade Consultancy
                </Link>
                <div className="dropdown-menu border-light m-0">
                  <Link to="/Trade" className="dropdown-item">
                    Pharmaceutical / Life Sciences
                  </Link>
                  <Link to="/Trade" className="dropdown-item">
                    Telecom
                  </Link>
                  <Link to="/Trade" className="dropdown-item">
                    Manufacturing
                  </Link>
                  <Link to="/Trade" className="dropdown-item">
                    Education
                  </Link>
                  <Link to="/Trade" className="dropdown-item">
                    Engineering
                  </Link>
                </div>
              </div>
              <Link to="/Contact" className="nav-item nav-link">
                Contact
              </Link>
            </div>
            <Link
              to="/Getquote"
              className="btn btn-primary py-3 px-5 animated slideInDown"
            >
              Place Quote
            </Link>
          </div>
        </nav>
      </div>
      {/* Navbar End */}
    </>
  );
};

export default Header;
