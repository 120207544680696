import ReactDOM from "react-dom";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from './pages/Home';
import Contact from "./pages/Contact";
import Ocean from "./pages/Ocean";
import Air from "./pages/Air";
import Road from "./pages/Road";
import Warehouse from "./pages/Warehouse";
import Project from "./pages/Project";

import Getquote from "./pages/Getquote";
import About from "./pages/About";
import Services from "./pages/Services";
import Privacy from "./pages/Privacy";
import Terms from "./pages/Terms";
import Customs from "./pages/Customs";




import Trade from "./pages/Trade";


export default function App() {
  return (
    <div>
      <Router>
        <Routes>
          <Route path="/Customs" element={<Customs />} />
          <Route path="/" element={<Home />} />
          <Route path="/Home" element={<Home />} />
          <Route path="/Contact" element={<Contact />} />
          <Route path="/Ocean" element={<Ocean/>} />
          <Route path="/Air" element={<Air />} />
          <Route path="/Road" element={<Road />} />
          <Route path="/Project" element={<Project />} />
          <Route path="/Getquote" element={<Getquote />} />
          <Route path="/About" element={<About />} />
          <Route path="/Services" element={<Services />} />
          <Route path="/Warehouse" element={<Warehouse />} />
          <Route path="/Trade" element={<Trade />} />
          <Route path="/Privacy" element={<Privacy />} />
          <Route path="/Terms" element={<Terms />} />

        </Routes>
      </Router>
    </div>
  );
}

ReactDOM.render(<App />, document.getElementById('root'));