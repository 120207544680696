import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <div
        className="container-fluid page-header py-6 wow fadeIn"
        data-wow-delay="0.1s"
      >
        <div className="container-fluid page-header-inner py-5">
          <div className="container text-center pb-5">
            <h1 className="display-3 text-white mb-3 animated slideInDown">
              Customs Clearance
            </h1>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb justify-content-center text-uppercase">
                <li className="breadcrumb-item">
                  <Link to="/Home">Home</Link>
                </li>

                <li
                  className="breadcrumb-item text-white active"
                  aria-current="page"
                >
                  Customs Clearance
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      <div className="container-xxl py-5">
        <div className="container">
          <div className="row g-5 align-items-center">
            <div className="col-lg-12 text-center">
              <h1 className="mb-4">Customs Clearance</h1>
              <img
                className="img-fluid rounded  wow zoomIn"
                data-wow-delay="0.1s"
                src="img/Customs Clearance.jpg"
              />
            </div>
            <div className="col-lg-12">
              <p>
                Our commitment to providing exceptional 24/7 quality support is
                rooted in several key components that form the bedrock of our
                services. One of these cornerstones is the creation of a
                comprehensive Client Import Policy and Procedures manual. This
                manual acts as a guide, ensuring that every step of the import
                process adheres to established protocols and compliance
                standards.
              </p>
              <p>
                Another crucial aspect of our support is classification and
                valuation assistance. We recognize the intricate nature of goods
                classification and valuation, and our experts are readily
                available to provide guidance and expertise in navigating these
                complexities. This ensures accurate classification and valuation
                for your imports, mitigating the risk of regulatory issues.
              </p>
              <p>
                Our commitment to transparency and efficiency is reflected in
                our provision of online tracking services. Through this system,
                you can monitor the progress of your shipments in real-time,
                enhancing visibility and enabling proactive decision-making.
                Moreover, our image processing system adds an extra layer of
                accuracy by documenting the condition of your goods through
                images.
              </p>
              <p>
                Access to our Global communications services further enhances
                the connectivity and collaboration that underpins our support.
                Clear and effective communication is essential for ensuring
                compliance with governmental regulations, and our global
                communications services facilitate seamless information exchange
                across borders, time zones, and regulatory environments.
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
