import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <div
        className="container-fluid page-header py-6 wow fadeIn"
        data-wow-delay="0.1s"
      >
        <div className="container-fluid page-header-inner py-5">
          <div className="container text-center pb-5">
            <h1 className="display-3 text-white mb-3 animated slideInDown">
              Project cargo logistics
            </h1>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb justify-content-center text-uppercase">
                <li className="breadcrumb-item">
                  <Link to="/Home">Home</Link>
                </li>

                <li
                  className="breadcrumb-item text-white active"
                  aria-current="page"
                >
                  Project cargo logistics
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      <div className="container-xxl py-5">
        <div className="container">
          <div className="row g-5 align-items-center">
            <div className="col-lg-12 text-center">
              <h1 className="mb-4">Project cargo logistics</h1>
              <img
                className="img-fluid rounded  wow zoomIn"
                data-wow-delay="0.1s"
                src="img/Project cargo logistics.jpg"
              />
            </div>
            <div className="col-lg-12">
              <p>
                Irrespective of the location, intricacy, or size, we possess the
                prowess to transport heavyweight and oversized cargo to its
                ultimate destination. Achieving this feat demands astute
                planning right from the initial stages to mitigate additional
                expenses and navigate through regulatory intricacies, including
                taxes and customs compliances.
              </p>
              <p>
                Our extensive experience shines through in delivering bespoke
                project cargo logistics, catering to a range of industries such
                as Oil & Gas, Renewable Energy, Mining, Port Equipment, Rail,
                and Manufacturing. These sectors demand precision and expertise
                due to their unique requirements, and we are well-equipped to
                rise to the occasion.
              </p>
              <p>
                Our adept team undertakes a comprehensive analysis of the
                project's scale and intricacies, orchestrating all the essential
                resources to ensure the most efficient delivery. From meticulous
                planning to seamless execution, our aim is to facilitate the
                transportation of specialized cargo while adhering to the
                highest standards of efficiency, safety, and compliance.
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
